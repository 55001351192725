$(document).ready(function () {
    const paramsString = new URL(location.href);
    const searchParams = new URLSearchParams(paramsString.search);
    const queryParamEvent = new Event('FFQueryParamInit');

    if (searchParams.has('__r') === true) {
        searchParams.delete('__r');
        window.history.replaceState({}, '', `${location.pathname}?${searchParams}`);
        document.dispatchEvent(queryParamEvent);
    }
})

document.addEventListener('FFQueryParamInit', function (e) {
    const contactform = $('#contact-form');
    $('html,body').animate({scrollTop: contactform.offset().top});
}, false);






